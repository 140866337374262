import React, { useCallback, useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { css } from '@emotion/core'
import throttle from 'lodash.throttle'
import { useEmblaCarousel } from 'embla-carousel/react'
import { useTheme } from '@/store'
import { SectionWrapper } from '@/components/SectionWrapper'
import { HomeSliderQuery } from '@/types/__generated__/HomeSliderQuery'
import { resolve_contentful_reference } from '@/utils/resolve_contentful_reference'
import { FeatherIcon } from '@/components/FeatherIcon'
import { Theme } from '@/config/theme'

type Props = {
  id: string
}

const query = graphql`
  query HomeSliderQuery {
    contentfulPageHome {
      slider {
        contentful_id
        title
        caption
        image {
          fluid(maxWidth: 512, maxHeight: 384, quality: 95) {
            ...ContentfulFluid
          }
        }
        link {
          __typename
          ... on ContentfulProject {
            slug
          }
          ... on ContentfulLookbook {
            slug
          }
        }
      }
    }
  }
`

const getButtonCss = (theme: Theme) => css`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  font-size: 2rem;
  padding: 2rem;
  color: ${theme.color.accent};
  transition: color 0.2s ease-out;

  &:disabled {
    color: ${theme.color.icon_disabled};
  }
`

export const HomeSlider: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const {
    contentfulPageHome: { slider },
  } = useStaticQuery<HomeSliderQuery>(query)
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    selectedClass: '',
  })
  // Sync state with embla, so we can style prev/next button
  const [isNextEnabled, setIsNextEnabled] = useState(false)
  const [isPrevEnabled, setIsPrevEnabled] = useState(false)

  const recalculate_slider = useCallback(
    throttle(() => {
      if (emblaApi) {
        setIsNextEnabled(emblaApi.canScrollNext())
        setIsPrevEnabled(emblaApi.canScrollPrev())
      }
    }, 200),
    [emblaApi],
  )

  useEffect(() => {
    recalculate_slider()
  }, [recalculate_slider])

  function scrollPrev() {
    emblaApi.scrollPrev()
  }
  function scrollNext() {
    emblaApi.scrollNext()
  }

  emblaApi &&
    emblaApi.on('scroll', () => {
      recalculate_slider()
    })

  return (
    <div
      css={css`
        box-shadow: inset 0 2rem 3rem 2rem rgba(${theme.color.shadow}, 0.1);
        padding-top: calc(2rem + 3%);
        ${theme.mq.md} {
          /* When slider buttons are shown, narrower top padding */
          /* Because the buttons’ paddings provide considerable whitespace */
          padding-top: 2rem;
        }
        overflow-x: hidden;
      `}
    >
      <SectionWrapper id={id} topPadding={false}>
        <div
          css={css`
            /* Hide on mobile */
            display: none;

            ${theme.mq.md} {
              display: flex;
              justify-content: flex-end;
            }
          `}
        >
          <button
            type="button"
            css={getButtonCss(theme)}
            onClick={scrollPrev}
            disabled={!isPrevEnabled}
          >
            <FeatherIcon name="chevron-left" />
          </button>
          <button
            type="button"
            css={getButtonCss(theme)}
            onClick={scrollNext}
            disabled={!isNextEnabled}
          >
            <FeatherIcon name="chevron-right" />
          </button>
        </div>

        <div ref={emblaRef}>
          <div
            css={css`
              display: flex;
              margin-left: -1rem;
              margin-right: -1rem;
            `}
          >
            {slider.map(slide => {
              const { contentful_id, image, title, caption, link } = slide
              const link_to = resolve_contentful_reference(link)

              return (
                <article
                  key={contentful_id}
                  css={css`
                    position: relative;
                    flex: 0 0 auto;
                    width: 80%;

                    padding-left: 1rem;
                    padding-right: 1rem;

                    ${theme.mq.sm} {
                      width: 45%;
                    }

                    ${theme.mq.lg} {
                      width: 30%;
                    }
                  `}
                >
                  <Link
                    to={link_to}
                    css={css`
                      text-decoration: none;
                    `}
                  >
                    <Img
                      key={title}
                      fluid={image.fluid}
                      alt=""
                      css={css`
                        box-shadow: ${theme.elevation[2]};
                        border-radius: ${theme.radius.photo};
                      `}
                    />
                    <h2
                      css={css`
                        margin-top: 0.75rem;
                        color: ${theme.color.text_default};
                        font-size: ${theme.size[3]};
                      `}
                    >
                      {title}
                    </h2>
                    <p
                      css={css`
                        margin-top: 0.25rem;
                        line-height: 1.4;
                        color: ${theme.color.text_default};
                        font-size: ${theme.size[2]};
                      `}
                    >
                      {caption}
                    </p>
                  </Link>
                </article>
              )
            })}
          </div>
        </div>
      </SectionWrapper>
    </div>
  )
}

import { useTheme } from '@/store'
import { HomeIntroductionQuery } from '@/types/__generated__/HomeIntroductionQuery'
import { padding } from '@/utils/mixins'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

type Props = {
  id: string
}

const query = graphql`
  query HomeIntroductionQuery {
    contentfulPageHome {
      introduction {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const HomeIntroduction: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const {
    contentfulPageHome: { introduction },
  } = useStaticQuery<HomeIntroductionQuery>(query)

  return (
    <section
      id={id}
      css={css`
        ${padding};
      `}
    >
      <div
        css={css`
          max-width: ${theme.maxWidthNarrow};
          margin: 0 auto;
        `}
      >
        <div
          css={css`
            font-size: ${theme.size[5]};
            line-height: 1.35;

            p + p {
              margin-top: 1rem;
            }
          `}
          dangerouslySetInnerHTML={{
            __html: introduction.childMarkdownRemark.html,
          }}
        />
      </div>
    </section>
  )
}

import React from 'react'
import { SectionWrapper } from '@/components/SectionWrapper'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import { useTheme } from '@/store'
import { fluidFontSize } from '@apostrof/lib'
import { HomeEndCtaQuery } from '@/types/__generated__/HomeEndCtaQuery'
import { resolve_contentful_reference } from '@/utils/resolve_contentful_reference'

type Props = {
  id: string
}

const query = graphql`
  query HomeEndCtaQuery {
    contentfulPageHome {
      primaryCtaTitle {
        childMarkdownRemark {
          html
        }
      }
      primaryCtaButtonText
      primaryCtaButtonLink {
        __typename
        ... on ContentfulProject {
          slug
        }
        ... on ContentfulLookbook {
          slug
        }
      }

      secondaryCtaTitle {
        childMarkdownRemark {
          html
        }
      }
      secondaryCtaButtonText
      secondaryCtaButtonLink {
        __typename
        ... on ContentfulProject {
          slug
        }
        ... on ContentfulLookbook {
          slug
        }
      }
    }
  }
`

export const HomeEndCta: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const {
    contentfulPageHome: {
      primaryCtaTitle,
      primaryCtaButtonText,
      primaryCtaButtonLink,
      secondaryCtaTitle,
      secondaryCtaButtonText,
      secondaryCtaButtonLink,
    },
  } = useStaticQuery<HomeEndCtaQuery>(query)

  const primaryLink = resolve_contentful_reference(primaryCtaButtonLink)
  const secondaryLink = resolve_contentful_reference(secondaryCtaButtonLink)

  return (
    <SectionWrapper id={id}>
      <div
        css={css`
          text-align: center;
          ${theme.mq.md} {
            display: flex;
            align-items: center;
          }
        `}
      >
        {/* Primary card */}
        <div
          css={css`
            position: relative;
            z-index: 2;
            background: ${theme.color.bg_default};
            padding: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: ${theme.elevation[3]};
            border-radius: ${theme.radius.card};
            border: solid 1px ${theme.color.line_contrasty};

            ${theme.mq.md} {
              padding: 4rem;
              width: 60%;
            }
          `}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: primaryCtaTitle.childMarkdownRemark.html,
            }}
            css={css`
              ${fluidFontSize(24, 40, 320, theme.vwImageCapWidth)};
              font-weight: 700;
              color: ${theme.color.text_light};

              strong {
                color: ${theme.color.accent};
              }
            `}
          />

          <Link
            to={primaryLink}
            css={css`
              margin-top: 2rem;
              padding: 0.75rem 2rem 1rem;
              background-color: ${theme.color.accent};
              color: ${theme.color.text_on_accent_bg};
              font-size: ${theme.size[4]};
              text-decoration: none;
              border-radius: ${theme.radius.button};
              font-weight: 700;
            `}
          >
            {primaryCtaButtonText}
          </Link>
        </div>

        {/* Secondary card */}
        <div
          css={css`
            position: relative;
            z-index: 1;
            margin: 2rem;
            margin-top: -1rem;
            padding: 2rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: ${theme.color.bg_default};
            border-radius: ${theme.radius.card};
            border: solid 1px ${theme.color.line};

            ${theme.mq.md} {
              width: 40%;
              margin: 0;
              margin-left: -1rem;
              padding-top: 2rem;
              padding-left: 3rem;
            }
          `}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: secondaryCtaTitle.childMarkdownRemark.html,
            }}
            css={css`
              color: ${theme.color.text_light};
              font-size: ${theme.size[4]};

              strong {
                color: ${theme.color.accent};
              }
            `}
          />

          <Link
            to={secondaryLink}
            css={css`
              margin-top: 2rem;
              text-decoration: none;

              padding: 0.25rem 1rem 0.5rem;
              border: solid 1px ${theme.color.accent};
              border-radius: ${theme.radius.button};
              color: ${theme.color.accent};
            `}
          >
            {secondaryCtaButtonText}
          </Link>
        </div>
      </div>
    </SectionWrapper>
  )
}

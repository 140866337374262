type ValidReferenceTypenames =
  | 'ContentfulProject'
  | 'ContentfulLookbook'
  | 'ContentfulPageAbout'
  | 'ContentfulPageContact'
  | 'ContentfulPagePortfolio'
  | 'ContentfulPagePortfolioLookbooks'
  | 'ContentfulPagePortfolioProjects'
  | 'ContentfulPageTestimonials'

export function resolve_contentful_reference({
  __typename,
  slug,
}: {
  __typename: ValidReferenceTypenames
  slug?: string | null
}) {
  switch (__typename) {
    case 'ContentfulProject':
      return `/portfolio/projects/${slug}/`
    case 'ContentfulLookbook':
      return `/portfolio/lookbooks/${slug}/`
    case 'ContentfulPageAbout':
      return '/about/'
    case 'ContentfulPageContact':
      return '/contact/'
    case 'ContentfulPagePortfolio':
      return '/portfolio/'
    case 'ContentfulPagePortfolioLookbooks':
      return '/portfolio/lookbooks/'
    case 'ContentfulPagePortfolioProjects':
      return '/portfolio/projects/'
    case 'ContentfulPageTestimonials':
      return '/testimonials/'
  }
}

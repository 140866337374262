import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { css } from '@emotion/core'
import { useTheme } from '@/store'
import { padding } from '@/utils/mixins'
import { resolve_contentful_reference } from '@/utils/resolve_contentful_reference'
import { HomeHighlightsQuery } from '@/types/__generated__/HomeHighlightsQuery'

type Props = {
  id: string
}

const query = graphql`
  query HomeHighlightsQuery {
    contentfulPageHome {
      highlights {
        __typename
        ... on ContentfulLookbook {
          contentful_id
          slug
          coverImage {
            fluid(maxWidth: 512, maxHeight: 341, quality: 95) {
              ...ContentfulFluid
            }
          }
        }
        ... on ContentfulProject {
          contentful_id
          slug
          coverImage {
            fluid(maxWidth: 512, maxHeight: 341, quality: 95) {
              ...ContentfulFluid
            }
          }
        }
      }
    }
  }
`

export const HomeHighlights: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const {
    contentfulPageHome: { highlights },
  } = useStaticQuery<HomeHighlightsQuery>(query)

  return (
    <section
      id={id}
      css={css`
        ${padding};
      `}
    >
      <div
        css={css`
          max-width: ${theme.maxWidth};
          margin: 0 auto;

          display: grid;
          grid-gap: 1rem;

          ${theme.mq.md} {
            grid-template-columns: 1fr 1fr;
          }
        `}
      >
        {highlights.map(highlight => {
          const link = resolve_contentful_reference(highlight)

          return (
            <div key={highlight.contentful_id}>
              <Link
                to={link}
                css={css`
                  display: block;
                  box-shadow: ${theme.elevation[2]};
                `}
              >
                <Img fluid={highlight.coverImage.fluid} alt="" />
              </Link>
            </div>
          )
        })}
      </div>
    </section>
  )
}

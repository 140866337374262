import { BrandDot } from '@/components/BrandDot'
import { SectionWrapper } from '@/components/SectionWrapper'
import { useTheme } from '@/store'
import { HomeServicesQuery } from '@/types/__generated__/HomeServicesQuery'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'

type Props = {
  id: string
}

const query = graphql`
  query HomeServicesQuery {
    contentfulPageHome {
      servicesHeading
      servicesSubheading {
        childMarkdownRemark {
          html
        }
      }
      residentialCoverImage {
        fluid(maxWidth: 512, quality: 95) {
          ...ContentfulFluid
        }
      }
      residentialDescription {
        childMarkdownRemark {
          html
        }
      }
      commercialCoverImage {
        fluid(maxWidth: 512, quality: 95) {
          ...ContentfulFluid
        }
      }
      commercialDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const HomeServices: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const {
    contentfulPageHome: {
      servicesHeading,
      servicesSubheading,
      residentialCoverImage,
      residentialDescription,
      commercialCoverImage,
      commercialDescription,
    },
  } = useStaticQuery<HomeServicesQuery>(query)

  return (
    <SectionWrapper id={id}>
      <div
        css={css`
          max-width: ${theme.maxWidthNarrow};
          margin: 0 auto;
          text-align: center;
        `}
      >
        <h2
          css={css`
            font-size: ${theme.size[7]};
            color: ${theme.color.text_default};
          `}
        >
          {servicesHeading}
          <BrandDot />
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: servicesSubheading.childMarkdownRemark.html,
          }}
          css={css`
            margin-top: 1rem;
            font-size: ${theme.size[4]};
            color: ${theme.color.text_light};
            line-height: 1.3;
          `}
        />
      </div>

      <div
        css={css`
          margin-top: 4rem;
          display: grid;
          grid-gap: 1rem;

          ${theme.mq.md} {
            grid-template-columns: 1fr 1fr;
          }
        `}
      >
        <Service
          imgFluid={residentialCoverImage.fluid}
          imgAlt="Residential"
          heading="Residential"
          descriptionHtml={residentialDescription.childMarkdownRemark.html}
        />
        <Service
          imgFluid={commercialCoverImage.fluid}
          imgAlt="Commercial"
          heading="Commercial"
          descriptionHtml={commercialDescription.childMarkdownRemark.html}
        />
      </div>
    </SectionWrapper>
  )
}

type ServiceProps = {
  imgFluid: FluidObject
  imgAlt: string
  heading: string
  descriptionHtml: string
}

function Service({ imgFluid, imgAlt, heading, descriptionHtml }: ServiceProps) {
  const theme = useTheme()

  return (
    <article>
      <Img fluid={imgFluid} alt={imgAlt} />
      <div
        css={css`
          margin-top: 1rem;
          padding-left: 1rem;
        `}
      >
        <h2
          css={css`
            font-size: ${theme.size[5]};
            color: ${theme.color.text_default};
          `}
        >
          {heading}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: descriptionHtml,
          }}
          css={css`
            margin-top: 0.75rem;
            color: ${theme.color.text_lighter};
            font-size: ${theme.size[3]};
            line-height: 1.4;
            padding-right: 2rem;
          `}
        />
      </div>
    </article>
  )
}

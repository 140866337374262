import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { css } from '@emotion/core'
import { fluidFontSize, scrimGradient } from '@apostrof/lib'
import { BrandDot } from '@/components/BrandDot'
import { useTheme } from '@/store'
import { HomeHeroQuery } from '@/types/__generated__/HomeHeroQuery'
import Img from 'gatsby-image'
import { padding, fontSmoothing } from '@/utils/mixins'
import { resolve_contentful_reference } from '@/utils/resolve_contentful_reference'

type Props = {
  id: string
}

const query = graphql`
  query HomeHeroQuery {
    contentfulPageHome {
      heroTitle
      heroSubtitle {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(maxWidth: 1024, quality: 100) {
          ...ContentfulFluidSVG
        }
      }
      ctaText
      ctaLink {
        __typename
        ... on ContentfulProject {
          slug
        }
        ... on ContentfulLookbook {
          slug
        }
      }
    }
  }
`

export const HomeHero: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const data = useStaticQuery<HomeHeroQuery>(query)
  const {
    heroImage,
    heroTitle,
    heroSubtitle,
    ctaText,
    ctaLink,
  } = data.contentfulPageHome

  const computedCtaLink = resolve_contentful_reference(ctaLink)

  return (
    <section
      id={id}
      css={css`
        ${padding};
      `}
    >
      <div
        css={css`
          position: relative;
          max-width: ${theme.maxWidth};
          margin: 0 auto;

          border-radius: ${theme.radius.photo};
          overflow: hidden;
          box-shadow: ${theme.elevation[2]};
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            padding: 5%;
            padding-right: 40%;

            ${scrimGradient({
              color: theme.color.grays[8],
              direction: 'to right',
              startAlpha: 0.8,
            })};
          `}
        >
          <div
            css={css`
              max-width: 24rem;
              height: 100%;

              color: ${theme.color.grays[0]};
              text-shadow: ${theme.textShadow};
              ${fontSmoothing};

              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              ${theme.mq.md} {
                justify-content: space-between;
              }
            `}
          >
            {/* Title */}
            <h1
              css={css`
                ${fluidFontSize(24, 36, 320, theme.vwImageCapWidth)};
                font-weight: 700;
                line-height: 1.1;
              `}
            >
              {heroTitle}
              <BrandDot />
            </h1>

            {/* Subtitle and CTA, tablet above only */}
            <div
              css={css`
                display: none;

                ${theme.mq.md} {
                  display: block;
                }
              `}
            >
              {/* Subtitle */}
              <div
                css={css`
                  ${fluidFontSize(14, 18, 320, theme.vwImageCapWidth)};
                  line-height: 1.4;

                  p {
                    margin-top: calc(32 / 24 * 1em);
                  }
                `}
                dangerouslySetInnerHTML={{
                  __html: heroSubtitle.childMarkdownRemark.html,
                }}
              />

              {/* CTA */}
              <div
                css={css`
                  margin-top: 3rem;
                `}
              >
                <Link
                  to={computedCtaLink}
                  css={css`
                    border-radius: ${theme.radius.button};
                    background-color: ${theme.color.accent};
                    padding: 0.5rem 2rem 0.75rem;
                    font-weight: 700;
                    text-shadow: none;

                    text-decoration: none;
                    color: ${theme.color.text_on_accent_bg};
                  `}
                >
                  {ctaText}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Img fluid={heroImage.fluid} alt="" />
      </div>

      {/* Subtitle and CTA, mobile only */}
      <div
        css={css`
          margin-top: 2rem;
          padding: 0 2rem;
          ${theme.mq.md} {
            display: none;
          }
        `}
      >
        {/* Subtitle */}
        <div
          css={css`
            text-align: center;
            font-size: ${theme.size[3]};
            line-height: 1.4;

            p {
              margin-top: calc(32 / 24 * 1em);
            }
          `}
          dangerouslySetInnerHTML={{
            __html: heroSubtitle.childMarkdownRemark.html,
          }}
        />

        {/* CTA */}
        <div
          css={css`
            margin-top: 2rem;
            display: flex;
            justify-content: center;
          `}
        >
          <Link
            to={computedCtaLink}
            css={css`
              border-radius: ${theme.radius.button};
              background-color: ${theme.color.accent};
              padding: 0.75rem 2rem 1rem;
              font-weight: 700;
              text-shadow: none;

              text-decoration: none;
              color: ${theme.color.grays[0]};
            `}
          >
            {ctaText}
          </Link>
        </div>
      </div>
    </section>
  )
}

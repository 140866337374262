import React from 'react'
import { graphql } from 'gatsby'
import { SEO } from '@/components/SEO'
import { HomeHero } from '@/sections/HomeHero'
import { HomeHighlights } from '@/sections/HomeHighlights'
import { HomeIntroduction } from '@/sections/HomeIntroduction'
import { HomeServices } from '@/sections/HomeServices'
import { HomeSlider } from '@/sections/HomeSlider'
import { HomeEndCta } from '@/sections/HomeEndCta'
import { HomepageQuery } from '@/types/__generated__/HomepageQuery'

export const query = graphql`
  query HomepageQuery {
    contentfulPageHome {
      metaDescription
    }
  }
`

type Props = {
  data: HomepageQuery
}

const Homepage: React.FC<Props> = ({ data }) => {
  const { metaDescription } = data.contentfulPageHome

  return (
    <>
      <SEO metaDescription={metaDescription} />

      <HomeHero id="home-hero" />

      <HomeSlider id="home-slider" />

      <HomeIntroduction id="home-introduction" />

      <HomeHighlights id="home-highlights" />

      <HomeServices id="home-services" />

      <HomeEndCta id="home-end-cta" />
    </>
  )
}

export default Homepage
